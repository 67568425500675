<template>
  <section class="addform">
    <div v-if="loading" class="loadingBox">
      <Loading />
    </div>
    <v-row v-if="!loading">
      <v-row>
        <v-col md="6" cols="6">
          <h2 class="mb-5 mt-5">Add Blog</h2>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation class="form-section">
        <v-row>
          <v-col md="12" cols="12" class="pt-8 mb-3">
            <v-row>
              <div class="px-3 marBtmMinus5">
                <h3>Medias</h3>
              </div>
              <v-col md="12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                ></vue-dropzone>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="12">
            <div class="d-flex">
              <div
                v-for="(data, index) in imagesUrl"
                :key="index + 'image'"
                class="imageAdded"
              >
                <v-icon @click="removeImages(data)"> mdi-close </v-icon>
                <img :src="data.media" alt="" />
              </div>
              <div
                v-for="(data, index) in videosUrl"
                :key="index + 'image'"
                class="imageAdded"
              >
                <v-icon @click="removeVideos(data)"> mdi-close </v-icon>
                <video controls>
                  <source :src="data.media" type="video/mp4" />
                </video>
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="4" class="pt-0">
            <strong>Category</strong>
            <v-select
              class="inputbox mt-3"
              v-model="category"
              label="Category"
              outlined
              :items="categories"
              item-text="name"
              item-value="name"
              @change="selectCategory(category)"
            ></v-select>
          </v-col>

          <v-col md="6" cols="4" class="pt-0">
            <strong>Sub-Category</strong>
            <v-select
              class="inputbox mt-3"
              v-model="subCategory"
              label="Sub Category"
              outlined
              :items="subCategories"
              item-text="name"
              item-value="name"
            ></v-select>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Blog Title</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="blogTitle"
              label="Blog Title"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>

          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Description</strong>
          </v-col>
          <v-col md="12" cols="12" class="pb-5">
            <vue-editor
              class="inputbox"
              v-model="description"
              placeholder="Description"
              v-if="false"
            ></vue-editor>
            <ckeditor v-model="description"></ckeditor>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Blog Meta Keyword</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="metaKeyword"
              label="Blog Title"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Blog Meta Description</strong>
          </v-col>
          <v-col md="12" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="metaDescription"
              label="Blog Title"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12" class="pb-0 pt-0">
            <strong>Rating</strong>
          </v-col>
          <v-col md="12" cols="12">
            <v-rating v-model="rating" size="30"></v-rating>
          </v-col>

          <v-col md="12" cols="12" class="submitbtn mb-10">
            <v-btn
              :disabled="!valid"
              large
              @click="validate"
              v-if="!submitloading"
            >
              Upload
            </v-btn>

            <v-btn class="submitbutton btn-dark" v-if="submitloading">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import Loading from "@/components/Loading";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "AddBlog",
  components: {
    VueEditor,
    Loading,
    vueDropzone: vue2Dropzone,
  },

  data: () => ({
    subCategories: [],
    blogTitle: undefined,
    categories: [],
    description: undefined,
    valid: true,
    loading: true,
    submitloading: false,
    images: [],
    videos: [],
    imagesUrl: [],
    videosUrl: [],
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    category: undefined,
    subCategory: undefined,
    rating: undefined,
    removedVideos: [],
    removedImages: [],
    metaKeyword: undefined,
    metaDescription: undefined,
  }),
  methods: {
    ...mapActions([
      "postBlog",
      "openSnackBar",
      "fetchCategory",
      "getSingleBlog",
    ]),
    removeImages(data) {
      let media = {};
      media.fileLocation = data.media.replace(
        "http://api.evinnepal.com/api",
        ""
      );
      media._id = data._id;
      this.removedImages.push(media);
      let images;
      images = this.imagesUrl.filter((ele) => {
        return ele._id != data._id;
      });
      this.imagesUrl = images;
    },
    removeVideos(data) {
      let media = {};
      media.fileLocation = data.media.replace(
        "http://api.evinnepal.com/api",
        ""
      );
      media._id = data._id;
      this.removedVideos.push(media);
      let videos;
      videos = this.videosUrl.filter((ele) => {
        return ele._id != data._id;
      });
      this.videosUrl = videos;
    },
    selectCategory(id) {
      this.subCategories = [];
      if (id != undefined) {
        let filteredData = this.allCategory.results.filter((ele) => {
          return id == ele.name;
        });
        this.subCategories = filteredData[0].subCategories;
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.submitloading = true;
        this.images = [];
        this.videos = [];
        let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
        fileBundles.forEach((ele) => {
          if (ele.type.split("/")[0] == "image") {
            this.images.push(ele);
          } else if (ele.type.split("/")[0] == "video") {
            this.videos.push(ele);
          }
        });

        let formData = new FormData();

        if (this._id != undefined) {
          formData.append("_id", this._id);
        }
        if (this.category != undefined) {
          formData.append("category", this.category);
        }

        if (this.subCategory != undefined) {
          formData.append("subCategory", this.subCategory);
        }
        if (this.removedVideos != undefined) {
          formData.append("removedVideos", JSON.stringify(this.removedVideos));
        }
        if (this.removedImages != undefined) {
          formData.append("removedImages", JSON.stringify(this.removedImages));
        }

        if (this.blogTitle != undefined) {
          formData.append("blogTitle", this.blogTitle);
        }

        if (this.description != undefined) {
          formData.append("description", this.description);
        }
        if (this.metaKeyword != undefined) {
          formData.append("metaKeyword", this.metaKeyword);
        }
        if (this.metaDescription != undefined) {
          formData.append("metaDescription", this.metaDescription);
        }

        if (this.$route.params.id != undefined) {
          formData.append("_id", this.$route.params.id);
        }

        if (this.images != undefined) {
          this.images.forEach((ele) => {
            formData.append("images", ele);
          });
        }

        if (this.videos != undefined) {
          this.videos.forEach((ele) => {
            formData.append("videos", ele.file);
          });
        }

        await this.postBlog(formData);
        this.submitloading = false;
        this.$router.push("/business/blogs");
      }
    },
  },
  computed: {
    ...mapGetters(["allCategory", "allProduct", "allBlog"]),
  },
  async created() {
    await this.fetchCategory();
    this.categories = this.allCategory.results;
    if (this.$route.params.id) {
      await this.getSingleBlog(this.$route.params.id);
      this.blogTitle = this.allBlog.results.blogTitle;
      this.description = this.allBlog.results.description;
      this.category = this.allBlog.results.category;
      this.selectCategory(this.category);
      this.subCategory = this.allBlog.results.subCategory;
      this.imagesUrl = this.allBlog.results.images;
      this.videosUrl = this.allBlog.results.videos;
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.marBtmMinus5 {
  margin-bottom: -20px;
}
.submitbtn {
  text-align: left;
  color: #fff;
  display: flex;
  .v-btn {
    width: 250px;
  }
  .v-btn {
    color: #fff;
  }
  .btn-cancel {
    background: #f4f4f4 !important;
    color: #000 !important;
  }
}
.v-btn {
  min-height: 50px;
}
.relBox {
  position: relative;
}
.imageLoop {
  margin-top: 10px;
  position: relative;
  .v-icon {
    position: absolute;
    background: black;
    color: #fff !important;
    right: -10px;
    top: -10px;
    border-radius: 50%;
  }
  img {
    width: 80px;
    height: 85px;
    object-fit: contain;
    border: 1px solid #d1cccc;
  }
}
.submitbtn {
  text-align: left;
  margin-left: 0 !important;
}

.nextbtn {
  margin-left: 10px;
}

h3 {
  margin-bottom: 20px;
}
.imageAdded {
  position: relative;
  .v-icon {
    position: absolute;
    background: rgb(224, 11, 11);
    border-radius: 50%;
    right: 20px;
    top: 10px;
    font-size: 16px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: #fff;
  }
  img,
  video {
    width: 200px;
    height: 150px;
    object-fit: contain;
    border: 1px solid #efefef;
    margin-right: 10px;
  }
}
</style>
