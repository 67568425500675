<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <AddBlog/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AddBlog from '@/components/AddBlog';
export default {
  name: "AddBlogPage",
  components:{
    AddBlog
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
</style>